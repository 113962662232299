module.exports = {
  'ar': require('../../i18n/ar.json'),
  'da': require('../../i18n/da.json'),
  'de': require('../../i18n/de.json'),
  'en': require('../../i18n/en.json'),
  'es': require('../../i18n/es.json'),
  'fr': require('../../i18n/fr-FR.json'),
  'fr-FR': require('../../i18n/fr-FR.json'),
  'he': require('../../i18n/he.json'),
  'it': require('../../i18n/it.json'),
  'ja': require('../../i18n/ja.json'),
  'ko': require('../../i18n/ko.json'),
  'nb-NO': require('../../i18n/nb-NO.json'),
  'nl': require('../../i18n/nl-NL.json'),
  'nl-NL': require('../../i18n/nl-NL.json'),
  'pl': require('../../i18n/pl.json'),
  'pt': require('../../i18n/pt.json'),
  'pt-BR': require('../../i18n/pt-BR.json'),
  'ru': require('../../i18n/ru.json'),
  'sv': require('../../i18n/sv.json'),
  'tlh': require('../../i18n/tlh.json'),
  'tr': require('../../i18n/tr.json'),
  'zh': require('../../i18n/zh.json'),
  'zh-TW': require('../../i18n/zh-TW.json')
}
